export function useHelpers() {
    function insertAt(array: any[], index: number, item: any) {
        // Ensure the index is within bounds
        if (index >= 0 && index <= array.length) {
            array.splice(index, 0, item); // Insert at the specific index
        }
        return array;
    }

    return {insertAt};
}